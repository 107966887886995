// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-custom-designs-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/custom-designs.tsx" /* webpackChunkName: "component---src-pages-custom-designs-tsx" */),
  "component---src-pages-faqs-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-gallery-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sports-afl-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/afl.tsx" /* webpackChunkName: "component---src-pages-sports-afl-tsx" */),
  "component---src-pages-sports-baseball-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/baseball.tsx" /* webpackChunkName: "component---src-pages-sports-baseball-tsx" */),
  "component---src-pages-sports-basketball-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/basketball.tsx" /* webpackChunkName: "component---src-pages-sports-basketball-tsx" */),
  "component---src-pages-sports-corporate-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/corporate.tsx" /* webpackChunkName: "component---src-pages-sports-corporate-tsx" */),
  "component---src-pages-sports-cricket-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/cricket.tsx" /* webpackChunkName: "component---src-pages-sports-cricket-tsx" */),
  "component---src-pages-sports-hi-vis-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/hi-vis.tsx" /* webpackChunkName: "component---src-pages-sports-hi-vis-tsx" */),
  "component---src-pages-sports-hockey-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/hockey.tsx" /* webpackChunkName: "component---src-pages-sports-hockey-tsx" */),
  "component---src-pages-sports-netball-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/netball.tsx" /* webpackChunkName: "component---src-pages-sports-netball-tsx" */),
  "component---src-pages-sports-rugby-league-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/rugby-league.tsx" /* webpackChunkName: "component---src-pages-sports-rugby-league-tsx" */),
  "component---src-pages-sports-rugby-union-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/rugby-union.tsx" /* webpackChunkName: "component---src-pages-sports-rugby-union-tsx" */),
  "component---src-pages-sports-schools-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/schools.tsx" /* webpackChunkName: "component---src-pages-sports-schools-tsx" */),
  "component---src-pages-sports-soccer-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/soccer.tsx" /* webpackChunkName: "component---src-pages-sports-soccer-tsx" */),
  "component---src-pages-sports-touch-football-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/sports/touch-football.tsx" /* webpackChunkName: "component---src-pages-sports-touch-football-tsx" */),
  "component---src-pages-stores-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/stores.tsx" /* webpackChunkName: "component---src-pages-stores-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

exports.data = () => import("/Users/jackedgson/Documents/turboclicks/gfit/website/.cache/data.json")

